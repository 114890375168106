import React from 'react';

/**
 * all props passed to the component
 * are spread on the container element.
 */
const SearchTray = (props) => {
  return (
    <div {...props} className="b-search-tray b-search-tray--blue">
      <div className="b-search-tray__item">{props.children}</div>
    </div>
  );
};

export default SearchTray;
