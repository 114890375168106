export const isSameTime = (date1, date2) => {
  return (
    new Date(date1).toLocaleDateString('nb-NO', {
      year: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }) ===
    new Date(date2).toLocaleDateString('nb-NO', {
      year: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  );
};

export const isSameDay = (date1, date2) => {
  return (
    new Date(date1).toLocaleDateString('nb-NO', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }) ===
    new Date(date2).toLocaleDateString('nb-NO', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  );
};

export const isSameMonth = (date1, date2) => {
  return (
    new Date(date1).toLocaleDateString('nb-NO', {
      year: 'numeric',
      month: 'long'
    }) ===
    new Date(date2).toLocaleDateString('nb-NO', {
      year: 'numeric',
      month: 'long'
    })
  );
};

export const isSameYear = (date1, date2) => {
  return (
    new Date(date1).toLocaleDateString('nb-NO', {
      year: 'numeric'
    }) ===
    new Date(date2).toLocaleDateString('nb-NO', {
      year: 'numeric'
    })
  );
};

const formatDateDayMonthYear = date =>
  date
    ? new Date(date).toLocaleDateString('nb-NO', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      })
    : null;

const formatDateDayMonth = date =>
  date
    ? new Date(date).toLocaleDateString('nb-NO', {
        day: 'numeric',
        month: 'long'
      })
    : null;

const formatDateDay = date =>
  date
    ? new Date(date).toLocaleDateString('nb-NO', {
        day: 'numeric'
      })
    : null;

const formatDateIntervalMonthAndYear = (startDate, endDate) => {
  return (
    formatDateDayMonthYear(startDate) + '–' + formatDateDayMonthYear(endDate)
  );
};

const formatDateIntervalSingleDay = (startDate, endDate) => {
  return formatDateDayMonthYear(startDate);
};

const formatDateIntervalSingleMonth = (startDate, endDate) => {
  return formatDateDay(startDate) + '–' + formatDateDayMonthYear(endDate);
};

const formatDateIntervalSingleYear = (startDate, endDate) => {
  return formatDateDayMonth(startDate) + '–' + formatDateDayMonthYear(endDate);
};

export const formatDateInterval = (startDate, endDate) => {
  if (isSameDay(startDate, endDate)) {
    return formatDateIntervalSingleDay(startDate, endDate);
  }
  if (isSameMonth(startDate, endDate)) {
    return formatDateIntervalSingleMonth(startDate, endDate);
  }
  if (isSameYear(startDate, endDate)) {
    return formatDateIntervalSingleYear(startDate, endDate);
  }
  return formatDateIntervalMonthAndYear(startDate, endDate);
};
