import React from 'react';
import { createRoot } from 'react-dom/client';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import MainHeader from './frontendComponents/MainHeader';
import InputSearch from './frontendComponents/InputSearch';
import ProductSearch from './frontendComponents/ProductSearch';
import ContentSearchWithTabs from './frontendComponents/ContentSearchWithTabs';
import SectionSidebar, { MobileDialog, StickyAside } from './frontendComponents/SectionSidebar';
import Loading from './frontendComponents/Loading';
import GlobalScopeScripts from './frontendComponents/utils/GlobalScopeScripts';
import TableWrapper from './frontendComponents/Table.wrapper';

const headerElement = document.getElementById('header');
const searchElement = document.getElementById('search');
const contentSearchElements = document.querySelectorAll('[data-content-search-with-tabs]');
const productSearchElement = document.getElementById('product-search');
const sectionSidebarElement = document.getElementById('sectionSidebar');
const sectionSidebarElementV2 = document.getElementById('sectionSidebarV2');
const sectionSidebarElementV2Dialog = document.getElementById('sectionSidebarV2Dialog');
const globalScopeScriptsElement = document.getElementById('globalScopeScripts');
const tables = document.querySelectorAll(".b-table__wrapper")

if (headerElement) {
  const headerRoot = createRoot(headerElement);
  const name = headerElement.getAttribute('data-name');
  const links = JSON.parse(headerElement.getAttribute('data-links'));
  const logoLinks = JSON.parse(headerElement.getAttribute('data-logo-links'));
  const linksMobile = JSON.parse(headerElement.getAttribute('data-links-mobile'));
  const logoHref = headerElement.getAttribute('data-logo-href') || '/';
  const exampleSuggestions = JSON.parse(headerElement.getAttribute('data-example-suggestions')) || [];
  const label = headerElement.getAttribute('data-label') || '';
  const qaEnvironment = headerElement.getAttribute('data-qaenvironment') === 'true';
  const showOuterLinks = headerElement.getAttribute('data-show-outer-links') === 'true';
  const showLogin = headerElement.getAttribute('data-show-login') === 'true';

  headerRoot.render(
    <MainHeader
      name={name}
      links={links}
      logoLinks={logoLinks}
      linksMobile={linksMobile}
      logoHref={logoHref}
      inputSearchLabel={label}
      exampleSuggestions={exampleSuggestions}
      qaEnvironment={qaEnvironment}
      showOuterLinks={showOuterLinks}
      showLogin={showLogin}
    />
  );
}

if (searchElement) {
  const searchRoot = createRoot(searchElement);
  const hideSuggestions = Boolean(
    searchElement.getAttribute('data-hide-suggestions') === "true"
  );
  const dark = Boolean(searchElement.getAttribute('data-dark') !== null);
  const label = searchElement.getAttribute('data-label') || '';
  const exampleSuggestions = JSON.parse(headerElement.getAttribute('data-example-suggestions')) || [];

  searchRoot.render(
    <InputSearch
      showSuggestions={!hideSuggestions}
      dark={dark}
      label={label}
      exampleSuggestions={exampleSuggestions}
    />
  );
}

if (productSearchElement) {
  const productSearchRoot = createRoot(productSearchElement);
  const label = productSearchElement.getAttribute('data-label') || '';
  const productId = productSearchElement.getAttribute('data-content-id') || '';
  const collapsed = Boolean(productSearchElement.getAttribute('data-collapsed') === 'true' || false);
  const malgruppe = productSearchElement.getAttribute('data-malgruppe') || '';
  const flatTree = productSearchElement.getAttribute('data-flatTree') || '';
  const endpoint = productSearchElement.getAttribute('data-endpoint') || '';

  productSearchRoot.render(
    <ProductSearch
      productId={productId}
      label={label}
      collapsed={collapsed}
      malgruppe={malgruppe}
      flatTree={flatTree}
      endpoint={endpoint}
    />
  );
}

if (contentSearchElements.length) {
  contentSearchElements.forEach(contentSearchElement => {
    const contentSearchRoot = createRoot(contentSearchElement);
    const id = contentSearchElement.getAttribute('data-content-id') || '';
    const flatTree = contentSearchElement.getAttribute('data-flattree') || [];
    const type = contentSearchElement.getAttribute('data-type') || '';
    const label = contentSearchElement.getAttribute('data-label') || '';
    const contentId = contentSearchElement.getAttribute('data-content-id') || '';
    const malgruppe = contentSearchElement.getAttribute('data-malgruppe') || '';
    const categories = contentSearchElement.getAttribute('data-categories') || [];
    const initial = contentSearchElement.getAttribute('data-initial') || Boolean(false);
    const collapsed = Boolean(contentSearchElement.getAttribute('data-collapsed') === "true" || false);
    const showLabel = Boolean(contentSearchElement.getAttribute('data-show-label') === "true" || false);
    const endpoint = contentSearchElement.getAttribute('data-endpoint') || '';
    const pageLength = contentSearchElement.getAttribute('data-page-length') || '';
    const orderBy = contentSearchElement.getAttribute('data-order-by') || '';
    const availableFilters = contentSearchElement.getAttribute('data-available-filters') || '';
    const showEntireList = contentSearchElement.getAttribute('data-show-entire-list') || false;
    const truncateListAfter = contentSearchElement.getAttribute('data-truncate-list-after') || 7;

    contentSearchRoot.render(
      <ContentSearchWithTabs
        id={id}
        label={label}
        flatTree={flatTree}
        type={type}
        contentId={contentId}
        malgruppe={malgruppe}
        categories={categories}
        collapsed={collapsed}
        initial={initial}
        endpoint={endpoint}
        pageLength={pageLength}
        orderBy={orderBy}
        showLabel={showLabel}
        availableFilters={availableFilters}
        showEntireList={showEntireList}
        truncateListAfter={parseInt(truncateListAfter)}
      />
    );
  })
}

if (sectionSidebarElement) {
  const sectionSidebarRoot = createRoot(sectionSidebarElement);
  const heading = sectionSidebarElement.getAttribute('data-heading');
  const icon = sectionSidebarElement.getAttribute('data-icon');
  const iconAltText = sectionSidebarElement.getAttribute('data-icon-alt-text');
  const headingUrl = sectionSidebarElement.getAttribute('data-heading-url');
  const list =
    sectionSidebarElement.getAttribute('data-list') === null
      ? []
      : JSON.parse(sectionSidebarElement.getAttribute('data-list'));

  sectionSidebarRoot.render(
    <SectionSidebar
      heading={heading}
      icon={icon}
      iconAltText={iconAltText}
      list={list}
      headingUrl={headingUrl}
    />
  );
}

if (sectionSidebarElementV2) {
  const sectionSidebarElementV2Root = createRoot(sectionSidebarElementV2);
  const heading = sectionSidebarElementV2.getAttribute('data-heading');
  const icon = sectionSidebarElementV2.getAttribute('data-icon');
  const iconAltText = sectionSidebarElementV2.getAttribute('data-icon-alt-text');
  const headingUrl = sectionSidebarElementV2.getAttribute('data-heading-url');
  const list =
  sectionSidebarElementV2.getAttribute('data-list') === null
      ? []
      : JSON.parse(sectionSidebarElementV2.getAttribute('data-list'));

  sectionSidebarElementV2Root.render(
    <StickyAside
      heading={heading}
      icon={icon}
      iconAltText={iconAltText}
      list={list}
      headingUrl={headingUrl}
    />
  );
}

if (sectionSidebarElementV2Dialog) {
  const sectionSidebarElementV2DialogRoot = createRoot(sectionSidebarElementV2Dialog);
  const heading = sectionSidebarElementV2Dialog.getAttribute('data-heading');
  const icon = sectionSidebarElementV2Dialog.getAttribute('data-icon');
  const iconAltText = sectionSidebarElementV2Dialog.getAttribute('data-icon-alt-text');
  const headingUrl = sectionSidebarElementV2Dialog.getAttribute('data-heading-url');
  const list =
  sectionSidebarElementV2Dialog.getAttribute('data-list') === null
      ? []
      : JSON.parse(sectionSidebarElementV2Dialog.getAttribute('data-list'));

  sectionSidebarElementV2DialogRoot.render(
    <MobileDialog
      heading={heading}
      icon={icon}
      iconAltText={iconAltText}
      list={list}
      headingUrl={headingUrl}
    />
  );
}

if (globalScopeScriptsElement) {
  const globalScopeRoot = createRoot(globalScopeScriptsElement);
  globalScopeRoot.render(
    <>
      <GlobalScopeScripts />
      <Loading message="PDF-versjon opprettes. Dette kan ta litt tid." />
    </>
  );
}

tables.forEach(table => {
  const tableRoot = createRoot(table);
  // removing the class since we're readding it in the component.
  table.classList.remove("b-table__wrapper")

  tableRoot.render(
    <TableWrapper table={table.innerHTML} />
  )
})
