import React from 'react';
import PropTypes from 'prop-types';
import { formatDateInterval, isSameDay, isSameTime } from './utils/formatDateInterval';

const MetaList = ({
  place,
  time,
  hourMinutes,
  startDate,
  endDate,
  deadline,
  audience,
  deadlineSeparate
}) => {

  const formatDate = (date) => date
  ? new Date(date).toLocaleTimeString('nb-NO', {
      hour: '2-digit',
      minute: '2-digit'
    })
  : null;

  const isLastYear = (date) => {
    return new Date(date).getFullYear() < new Date().getFullYear()
  }

  return (
    <ul className="b-meta-list">
      {time || isLastYear(startDate) && (
        <li className="b-meta-list__item">
          <span className="b-meta-list__icon b-meta-list__icon--calendar"></span>
          {isLastYear(startDate) ? (
            formatDateInterval(startDate, endDate)
          ) : time}
        </li>
      )}
      {hourMinutes && (
        <li className="b-meta-list__item">
          <span className="b-meta-list__icon b-meta-list__icon--clock"></span>
          {hourMinutes}
        </li>
      )}
      {startDate && isSameDay(startDate, endDate) && (
        <li className="b-meta-list__item">
          <span className="b-meta-list__icon b-meta-list__icon--clock"></span>
          <span>
            {isSameTime(startDate, endDate) ? formatDate(startDate) : (
              formatDate(startDate) + '–' + formatDate(endDate)
            )}
          </span>
        </li>
      )}
      {place && (
        <li className="b-meta-list__item">
          <span className="b-meta-list__icon b-meta-list__icon--map-pin"></span>
          {place}
        </li>
      )}
      {audience && (
        <li className="b-meta-list__item">
          <span className="b-meta-list__icon b-meta-list__icon--target-group"></span>
          {audience}
        </li>
      )}
      {deadline && (
        <li className={`b-meta-list__item ${deadlineSeparate ? 'b-meta-list__item--separate' : ''}`}>
          <span className={`b-meta-list__icon b-meta-list__icon--info-black ${deadlineSeparate ? 'b-meta-list__icon--large' : ''}`}></span>
          {deadline}
        </li>
      )}
    </ul>
  );
};

MetaList.propTypes = {
  place: PropTypes.string,
  time: PropTypes.string,
  audience: PropTypes.string
};

export default MetaList;
