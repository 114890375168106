import React, { useEffect, useState } from 'react';
import { transformInitial } from './ContentSearchWithTabs';
import CheckBoxGroup from './CheckboxGroup';
import Button from './Button';

// TODO: switch these with icons from static/icons folder, fetch from backend.
import FilterIcon from './icons/filter';
import CloseIcon from './icons/close';

/**
 * Filter the list with a simple check: does this item contain
 * one or more of the selected filterItems? if so, show it.
 *
 * Show item if: filterGroup1A OR filterGroup1B OR filterGroup2A
 */
const simpleOrFilter = (list, activeFilters) => {
  const isActiveFiltersEmpty = isFilterObjectEmpty(activeFilters);
  // loop over each tab
  const data = transformInitial(list);
  const keys = Object.keys(data);

  keys.map(tabName => {
    // loop over each item in tab
    data[tabName] = data[tabName].map(listItem => {
      let hideItem = true;

      // skip all checks and show all items if there are no active filters
      if (isActiveFiltersEmpty) {
        listItem.hidden = false;
        return listItem;
      }
      // hide items by default if it has no filters property
      if (!listItem.fields.filters) {
        listItem.hidden = true;
        return listItem;
      }

      // get list of tuples with key/valueArray of filters
      const filters = Object.entries(listItem.fields.filters);
      // loop over present filters on listItem

      // Er dette den eneste delen av filterAlgoritmen som er unikt
      // i forhold til det som skal gjøres for å filtere?
      filters.map(([key, array]) => {
        // check if active array contains filter-group
        if (!activeFilters[key]?.length) return;
        // if the is not empty, check to see if filter contains tags from "filters" on listItem
        if (activeFilters[key].some(filterItem => array.includes(filterItem))) {
          hideItem = false;
        }
      });
      if (hideItem) {
        listItem.hidden = true;
      } else {
        listItem.hidden = false;
      }
      return listItem;
    });
  });

  // since we need to reassign the data-object
  // to trigger a rerender
  return deepCloneObject(data);
};

/**
 * Filter the list, one filterGroup at a time. each group will use an OR
 * logic internally, and then use an AND logic between groups. This way,
 * more chencks in one group expands the list, while adding checks to other groups
 * will filter out.
 *
 * show item if: filterGroup1A OR filterGroup1B AND filterGroup2B OR filterGroup2C
 */
const orInGroupAndBetweenGroupsFilter = (list, activeFilters) => {
  const isResultArrayTrue = resultArray => {
    return resultArray.every(item => item);
  };

  const isActiveFiltersEmpty = isFilterObjectEmpty(activeFilters);
  // loop over each tab
  const data = transformInitial(list);
  const keys = Object.keys(data);

  keys.map(tab => {
    // loop over each item in tab
    data[tab] = data[tab].map(listItem => {
      // skip all checks and show all items if there are no active filters
      if (isActiveFiltersEmpty) {
        listItem.hidden = false;
        return listItem;
      }
      // hide items by default if it has no filters property
      if (!listItem.fields.filters) {
        listItem.hidden = true;
        return listItem;
      }

      const resultArray = [];
      Object.entries(activeFilters).map(
        ([filterGroupKey, filterGroupValues]) => {
          // when the list is empty, we dont want that to effect the search
          // so we push tru to result array and return early
          if (filterGroupValues.length === 0) {
            resultArray.push(true);
            return;
          }

          const listItemFilters = listItem.fields.filters;
          if (
            !listItemFilters[filterGroupKey] ||
            !listItemFilters[filterGroupKey].length
          ) {
            resultArray.push(false);
            return;
          }

          const result = listItemFilters[filterGroupKey].reduce(
            (result, current) => {
              if (filterGroupValues.includes(current)) {
                result = true;
              }
              return result;
            },
            false
          );

          resultArray.push(result);
        }
      );

      if (isResultArrayTrue(resultArray)) {
        listItem.hidden = false;
      } else {
        listItem.hidden = true;
      }
      return listItem;
    });
  });

  // since we need to reassign the data-object
  // to trigger a rerender
  return deepCloneObject(data);
};

// filterMap lets us make a simple API for use
// in different components. We keep the filtering algorithm
// inside the component for now.
const filterMap = {
  simpleOrFilter,
  orInGroupAndBetweenGroupsFilter
};

// minimal helper to handle both objects and JSON
const handleJSON = data => {
  if (typeof data === 'string') {
    return JSON.parse(data);
  }
  return data;
};

const deepCloneObject = object => {
  return JSON.parse(JSON.stringify(object));
};

// this lets us check if activeFilters is empty
const isFilterObjectEmpty = obj => {
  const objectKeys = Object.keys(obj);

  // return early if object has no properites
  if (objectKeys.length === 0) return true;

  let empty = true;
  objectKeys.map(key => {
    if (!obj[key]) return;

    if (obj[key].length !== 0) {
      empty = false;
    }
  });
  return empty;
};

const capitalize = string => {
  const firstLetter = string.charAt(0).toUpperCase();
  const rest = string.slice(1);

  return firstLetter + rest;
};

// TODO: Write tests
const generateFilterGroups = availableFilters => {
  return availableFilters
    .map(group => {
      const filterGroup = {};

      // this is the displayed name for a group.
      // we do it this way to simplify logic for
      // enonic implementation
      filterGroup.label = group.label;

      filterGroup.filterName = group.field;
      filterGroup.options = [];

      group.values
        .map(option => {
          const obj = {};
          obj.label = capitalize(option);
          obj.value = option;
          obj.checked = false;
          filterGroup.options.push(obj);
        })

      return filterGroup;
    })
    .filter(group => group.options.length);
};

/**
 * To make it work you need to add a useState
 * with the unfiltered list to parent
 * and pass the state and setState func as a prop.
 *
 */
export const ContentSearchFilter = ({
  availableFilters,
  filteredList, // filtered list for parent to use
  setFilteredList, // setState function to set the list in parent
  listChanged,
  setListChanged,
  filterAlgorithm = 'orInGroupAndBetweenGroupsFilter' // defines the type of list in use to let us use different filter functions. use the functions name
}) => {
  const [filterState, setFilterState] = useState(
    generateFilterGroups(handleJSON(availableFilters))
  );
  const [activeFilters, setActiveFilters] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // updates list when filterArray changes
  //TODO: add show all check. this happens when activeFilters is empty
  useEffect(() => {
    const newList = filterMap[filterAlgorithm](filteredList, activeFilters);
    setFilteredList(newList);
  }, [activeFilters]);

  useEffect(() => {
    if (listChanged) {
      const newList = filterMap[filterAlgorithm](filteredList, activeFilters);

      setFilteredList(newList);
      setListChanged(false);
    }
  }, [listChanged]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const resetFilter = () => {
    const resetFilter = filterState.map(obj => {
      obj.options = obj.options.map(option => {
        option.checked = false;
        return option;
      });
      return obj;
    });
    setFilterState(resetFilter);
    setActiveFilters({});
  };

  const createNewActiveFilters = () => {
    return filterState
      .map(filterGroup => {
        filterGroup.options = filterGroup.options
          .filter(option => option.checked)
          .map(option => option.value);
        return filterGroup;
      })
      .reduce((acc, curr) => {
        acc[curr.filterName] = curr.options;
        return acc;
      }, {});
  };

  /**
   * returns a function to change the
   * CheckBoxGroup with correct index
   *
   * @param {number} i
   * @returns {CallableFunction}
   */
  const createFilterStateHandler = i => {
    return newList => {
      filterState[i].options = newList;
      setFilterState(deepCloneObject(filterState));
      setActiveFilters(createNewActiveFilters(filterState));
    };
  };

  const filterTags = () => {
    const buttonGroup = [];

    filterState.map((group, indexOuter) => {
      group.options.map((option, indexInner) => {
        if (!option.checked) return;

        buttonGroup.push({
          indexOuter,
          indexInner,
          text: option.label
        });
      });
    });

    return buttonGroup;
  };
  // handler for filterTags
  const handleClearTag = (indexOuter, indexInner) => () => {
    filterState[indexOuter].options[indexInner].checked = false;
    setFilterState(deepCloneObject(filterState));
    setActiveFilters(createNewActiveFilters(filterState));
  };

  const numberOfShownListItems = filteredList => {
    const shownItems = Object.values(transformInitial(filteredList))
      .flat(1)
      .filter(item => !item.hidden);

    return shownItems.length;
  };

  return (
    <div className="b-content-filter">
      <div className="b-content-filter__buttons">
        {/* TODO: Add the correct aria labels to button and "open" region */}
        <Button
          className="b-content-filter__button"
          secondary
          onClick={toggle}
          aria-expanded={isOpen}
          aria-controls="filter-box"
          aria-label="åpne boks med filtere for innhold"
        >
          {isOpen ? (
            <>
              <CloseIcon /> Lukk
            </>
          ) : (
            <>
              <FilterIcon /> Filter
            </>
          )}
        </Button>
        <div className="b-content-filter__filter-tags">
          {filterTags().map(({ indexOuter, indexInner, text }) => (
            <>
              <Button
                className="b-content-filter__filter-tag"
                key={indexOuter + indexInner + text}
                onClick={handleClearTag(indexOuter, indexInner)}
              >
                {text} <CloseIcon />
              </Button>
            </>
          ))}
        </div>
      </div>

      {isOpen && (
        <div
          role="region"
          aria-label="søkefilter"
          id="filter-box"
          className="b-content-filter__open"
        >
          <div className="b-content-filter__reset">
            <Button
              onClick={resetFilter}
              className="b-content-filter__reset-button"
              plain
            >
              Nullstill
            </Button>
          </div>
          <div className="b-content-filter__filter-group">
            {filterState.map(({ label, options }, index) => {
              return (
                <div key={index} className="b-content-filter__checkbox-group">
                  <CheckBoxGroup
                    key={label}
                    options={options}
                    name={label}
                    heading={label}
                    handleChange={createFilterStateHandler(index)}
                  />
                </div>
              );
            })}
          </div>

          <div className="b-content-filter__show-results">
            <Button
              small
              onClick={toggle}
              aria-controls="filter-box"
              aria-expanded="true"
            >
              Vis resultater
            </Button>
          </div>
        </div>
      )}
      <div className="b-content-filter__results-number" aria-live="polite">
        Viser {numberOfShownListItems(filteredList)} treff
      </div>
    </div>
  );
};
