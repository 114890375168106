import React from "react";

const FilterIcon = () => {
  return (
    <>
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 2H12" stroke="currentColor" />
        <path d="M0 7H12" stroke="currentColor" />
        <path d="M0 12H12" stroke="currentColor" />
        <path
          d="M2.99999 3.49998C3.82841 3.49998 4.5 2.82844 4.5 2C4.5 1.17157 3.82843 0.5 3 0.5C2.17157 0.5 1.5 1.17157 1.5 2C1.5 2.82843 2.17157 3.49998 2.99999 3.49998Z"
          fill="white"
          stroke="currentColor"
        />
        <circle cx="3" cy="12" r="1.5" fill="white" stroke="currentColor" />
        <circle cx="9" cy="7" r="1.5" fill="white" stroke="currentColor" />
      </svg>
    </>
  );
};

export default FilterIcon