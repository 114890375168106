import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SquareImage from './SquareImage';
import Collapsible from './Collapsible';
import MetaList from './MetaList';
import IconWithText from './IconWithText';

const ListItemType = props => {
  const { type = '', fields = null } = props.itemType;

  switch (type) {
    case 'news':
      return <News fields={fields} />;

    case 'conference':
      return <Conferance fields={fields} />;

    case 'pressRelease':
      return <PressRelease fields={fields} />;

    case 'grantFunding':
      return <GrantFunding fields={fields} />;

    case 'generic':
      return <Generic fields={fields} />;

    case 'tilskudd':
    case 'grant':
      return <GrantOrFunding fields={fields} />;

    case 'arrangement':
      return <Arrangement fields={fields} />;

    case 'genericWithDateCircle':
      return <GenericWithDataCircle fields={fields} />;

    case 'archive':
      return <Archive fields={fields} />;

    case 'person':
      return <Person fields={fields} />;

    case 'statistikkelement':
      return <StatistikkElement fields={fields} />;

    case 'rapport':
      return <Report fields={fields} />;

    default:
      return <Generic fields={fields} />;
  }
};

ListItemType.propTypes = {
  itemType: PropTypes.shape({
    type: PropTypes.oneOf([
      'archive',
      'arrangement',
      'conference',
      'generic',
      'genericWithDateCircle',
      'grant',
      'grantFunding',
      'news',
      'person',
      'pressRelease',
      'rapport',
      'statistikkelement',
      'tilskudd'
    ]),
    fields: PropTypes.object
  }).isRequired
};
export default ListItemType;

const StatistikkElement = ({ fields }) => {
  return (
    <div
      key={fields.heading}
      className={classNames({
        'b-list-item-type': true,
        'b-list-item-type__flex-wrapper': true,
      })}
    >
      <div className="l-mt-1">
        <div className="b-list-item-type__text">
          {fields.heading && <h3>{fields.heading}</h3>}
        </div>
        {fields.tags && (
          <div className="b-tags">
            {fields.tags.map(tag => (
              <div key={tag.label} className="b-tags__item">
                {
                  tag.icon
                    ? <IconWithText size={"small"} icon={tag.icon} text={tag.label} />
                    : tag.label
                }
              </div>
            ))}
          </div>
        )}
        {fields.intro && (
          <div className='b-list-item-type__intro'>
            {fields.intro}
          </div>
        )}
        {/* TODO: this will probably change  */}
        {fields.lastUpdate && (
          <div className='b-list-item-type__last-update'>
            <IconWithText size={"small"} icon='/icons/clock.svg' text={`Sist faglig oppdatert: ${fields.lastUpdate}`} />
          </div>
        )}
      </div>
    </div>
  )
}

const News = ({ fields }) => {
  return (
    <div
      className={classNames({
        'b-list-item-type': true,
        'b-list-item-type--no-arrow': fields.download
      })}
    >
      <div className="b-list-item-type__flex-wrapper">
        <div className="b-list-item-type__text">
          {fields.heading && <h3>{fields.heading}</h3>}
          {fields.category && (
            <p className="b-list-item-type__category">{fields.category}</p>
          )}
          {fields.publishDate && (
            <div className="b-list-item-type__date">{fields.publishDate}</div>
          )}
          {fields.lead && (
            <p className="b-list-item-type__lead">{fields.lead}</p>
          )}
          {fields.topic && (
            <div className="b-list-item-type__topic">{fields.topic}</div>
          )}
        </div>
        {fields.download && (
          <div className="b-list-item-type__download" aria-label="Last ned PDF">
            PDF
          </div>
        )}
      </div>
    </div>
  );
};

const Conferance = ({ fields }) => {
  return (
    <div
      className={classNames({
        'b-list-item-type': true,
        'b-list-item-type--no-arrow': fields.download
      })}
    >
      <div className="b-list-item-type__flex-wrapper">
        <div className="b-list-item-type__text">
          {fields.heading && <h3>{fields.heading}</h3>}
          {fields.category && (
            <p className="b-list-item-type__category">{fields.category}</p>
          )}
          {fields.dateFrom && fields.dateTo ? (
            <p className="b-list-item-type__date">
              {fields.dateFrom} - {fields.dateTo}
            </p>
          ) : (
            <p className="b-list-item-type__date">{fields.dateTo}</p>
          )}
          {fields.lead && (
            <p className="b-list-item-type__lead">{fields.lead}</p>
          )}
          {fields.registrationDeadline && (
            <p className="b-list-item-type__deadline">
              <strong>Påmeldingsfrist: </strong>
              {fields.registrationDeadline}
            </p>
          )}
          {fields.location && (
            <div className="b-list-item-type__meta">
              <strong>By/region: </strong>
              {fields.location}
            </div>
          )}
          {fields.topic && (
            <div className="b-list-item-type__topic">{fields.topic}</div>
          )}
        </div>
        {fields.download && (
          <div className="b-list-item-type__download" aria-label="Last ned PDF">
            PDF
          </div>
        )}
      </div>
    </div>
  );
};

const PressRelease = ({ fields }) => {
  return (
    <div
      className={classNames({
        'b-list-item-type': true,
        'b-list-item-type--no-arrow': fields.download
      })}
    >
      <div className="b-list-item-type__flex-wrapper">
        <div className="b-list-item-type__image">
          <SquareImage src={fields.image} alt={fields.heading} />
        </div>
        <div className="b-list-item-type__text">
          {fields.heading && <h3>{fields.heading}</h3>}
          {fields.category && (
            <p className="b-list-item-type__category">{fields.category}</p>
          )}
          {fields.publishDate && (
            <div className="b-list-item-type__date">
              <span>Sist faglig oppdatert: </span>
              {fields.publishDate}
            </div>
          )}
        </div>
        {fields.download && (
          <div className="b-list-item-type__download" aria-label="Last ned PDF">
            PDF
          </div>
        )}
      </div>
      {fields.topic && (
        <div className="b-list-item-type__topic">{fields.topic}</div>
      )}
    </div>
  );
};

const GrantFunding = ({ fields }) => {
  return (
    <div
      className={classNames({
        'b-list-item-type': true,
        'b-list-item-type--no-arrow': fields.download
      })}
    >
      <div className="b-list-item-type__flex-wrapper">
        <div className="b-list-item-type__text">
          {fields.heading && <h3>{fields.heading}</h3>}
          {fields.category && (
            <p className="b-list-item-type__category">{fields.category}</p>
          )}

          {fields.deadline && (
            <p className="b-list-item-type__deadline">{fields.deadline}</p>
          )}
          {fields.topic && (
            <div className="b-list-item-type__topic">{fields.topic}</div>
          )}
        </div>
        {fields.download && (
          <div className="b-list-item-type__download" aria-label="Last ned PDF">
            PDF
          </div>
        )}
      </div>
    </div>
  );
};

const Generic = ({ fields }) => {
  return (
    <div
      className={classNames({
        'b-list-item-type': true,
        'b-list-item-type--no-arrow': fields.download || fields.openPdf
      })}
    >
      <div className="b-list-item-type__flex-wrapper">
        <div className="b-list-item-type__text">
          {fields.heading && <h3>{fields.heading}</h3>}
          {fields.category && (
            <p className="b-list-item-type__category">{fields.category}</p>
          )}

          {fields.subheading && (
            <div className="b-list-item-type__subheading">
              {Array.isArray(fields.subheading) ? (
                fields.subheading.map(text => <span>{text}</span>)
              ) : (
                <span>{fields.subheading}</span>
              )}
            </div>
          )}
        </div>
        {fields.download && (
          <div className="b-list-item-type__download" aria-label="Last ned PDF">
            PDF
          </div>
        )}
        {fields.openPdf && (
          <div
            className="b-list-item-type__download b-list-item-type__download--open"
            aria-label="Åpne PDF"
          >
            PDF
          </div>
        )}
      </div>
      {fields.topic && (
        <div className="b-list-item-type__topic">{fields.topic}</div>
      )}
      {fields.tags && (
        <div className="b-tags">
          {fields.tags.map(tag => (
            <div className="b-tags__item">{tag.label}</div>
          ))}
        </div>
      )}
    </div>
  );
};

const GrantOrFunding = ({ fields }) => {
  return (
    <div
      key={fields.heading}
      className={classNames({
        'b-list-item-type': true,
        'b-list-item-type__flex-wrapper': true,
        'b-list-item-type--no-arrow': fields.download || fields.openPdf
      })}
    >
      <div
        className={`b-list-item-type__date-circle ${fields.expired &&
          'b-list-item-type__date-circle--expired'} ${fields.generic &&
          'b-list-item-type__date-circle--generic'}`}
      >
        <span className="b-list-item-type__date-big">
          {fields.frist && fields.frist.day && fields.frist.day}
        </span>
        {fields.frist && fields.frist.month ? fields.frist.month : 'Løpende'}
      </div>
      <div className="">
        <div className="b-list-item-type__text">
          {fields.heading && <h3>{fields.heading}</h3>}
        </div>
        {fields.download && (
          <div className="b-list-item-type__download" aria-label="Last ned PDF">
            PDF
          </div>
        )}
        {fields.openPdf && (
          <div
            className="b-list-item-type__download b-list-item-type__download--open"
            aria-label="Åpne PDF"
          >
            PDF
          </div>
        )}
        {fields.tags && (
          <div className="b-tags">
            {fields.tags.map(tag => (
              <div className="b-tags__item" key={tag.label}>{tag.label}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const Arrangement = ({ fields }) => {
  let visibleDate = '';
  let startDatoWithTime = '';
  let sluttDatoWithTime = '';

  /* For Arrangementer */
  const setVisibleDate = () => {
    let visibleDate = '';
    if (!fields.startdato || !fields.sluttdato) {
      return '';
    }
    if (fields.startdato.day === fields.sluttdato.day) {
      visibleDate = fields.startdato.day;
    } else {
      visibleDate = fields.startdato.day + '-' + fields.sluttdato.day;
    }
    return visibleDate;
  };

  if (fields.startdato && fields.sluttdato) {
    startDatoWithTime = `${fields.startdato.date}T${fields.startdato.hour}:${fields.startdato.minute}:00`;
    sluttDatoWithTime = `${fields.sluttdato.date}T${fields.sluttdato.hour}:${fields.sluttdato.minute}:00`;
  }

  let circleClasses = 'b-list-item-type__date-circle';
  visibleDate = setVisibleDate();

  if (fields.expired) {
    circleClasses = circleClasses + ' b-list-item-type__date-circle--expired';
  }
  if (fields.generic) {
    circleClasses = circleClasses + ' b-list-item-type__date-circle--generic';
  }
  if (fields.color && fields.color === 'red') {
    circleClasses = circleClasses + ' b-list-item-type__date-circle--expired';
  }
  if (fields.color && fields.color === 'green') {
    circleClasses = circleClasses + ' b-list-item-type__date-circle--active';
  }
  if (!fields.expired && !fields.generic && !fields.color) {
    circleClasses = circleClasses + ' b-list-item-type__date-circle--generic';
  }

  return (
    <div
      key={fields.heading}
      className={classNames({
        'b-list-item-type': true,
        'b-list-item-type__flex-wrapper': true,
        'b-list-item-type--no-arrow': fields.download || fields.openPdf
      })}
    >
      <div className={circleClasses}>
        <span className="b-list-item-type__date-big">{visibleDate}</span>
        {fields.startdato && fields.startdato.month
          ? fields.startdato.month
          : 'Løpende'}
      </div>
      <div className="">
        <div className="b-list-item-type__text">
          {fields.heading && <h3>{fields.heading}</h3>}
        </div>
        <MetaList
          time={fields.time}
          place={fields.sted}
          startDate={fields.startdato && startDatoWithTime}
          endDate={fields.sluttdato && sluttDatoWithTime}
          deadline={fields.status}
        />
        {fields.tags && (
          <div className="b-tags">
            {fields.tags.map(tag => (
              <div className="b-tags__item" key={tag.label}>{tag.label}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const GenericWithDataCircle = ({ fields }) => {
  return (
    <div
      key={fields.heading}
      className={classNames({
        'b-list-item-type': true,
        'b-list-item-type__flex-wrapper': true,
        'b-list-item-type--no-arrow': fields.download || fields.openPdf
      })}
    >
      <div
        className={`b-list-item-type__date-circle ${fields.expired &&
          'b-list-item-type__date-circle--expired'} ${fields.generic &&
          'b-list-item-type__date-circle--generic'}`}
      >
        <span className="b-list-item-type__date-big">
          {fields.frist && fields.frist.day && fields.frist.day}
        </span>
        {fields.frist && fields.frist.month ? fields.frist.month : 'Løpende'}
      </div>
      <div className="">
        <div className="b-list-item-type__text">
          {fields.heading && <h3>{fields.heading}</h3>}
        </div>
        {fields.tags && (
          <div className="b-tags">
            {fields.tags.map(tag => (
              <div className="b-tags__item">{tag.label}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const Archive = ({ fields }) => {
  return (
    <div
      className={classNames({
        'b-list-item-type': true
      })}
    >
      <div className="b-list-item">
        <div className="b-list-item-type__text">
          {fields.download && (
            <a
              href="#pdf"
              className="b-list-item-type__download b-list-item-type--float b-list-item-type--link"
              aria-label="Last ned PDF"
            >
              PDF
            </a>
          )}
          {fields.heading && (
            <h3>
              {fields.heading}{' '}
              {fields.current && (
                <span className="b-list-item-type__current">
                  Gjeldende versjon
                </span>
              )}
            </h3>
          )}
          <div className="b-list-item-type__date">
            {fields.publishDate && fields.publishDate}
          </div>
        </div>
        {fields.lead && (
          <div className="b-list-item-type__subheading">
            <Collapsible heading="Endringer i denne versjonen" size="small">
              <p
                className="b-list-item-type__lead"
                dangerouslySetInnerHTML={{ __html: fields.lead }}
              />
            </Collapsible>
          </div>
        )}
      </div>
    </div>
  );
};

const Person = ({ fields }) => {
  return (
    <div className="b-list-item-type b-list-item-type--no-arrow">
      {fields.name && <strong>{fields.name}</strong>}
      <br />
      {fields.category && (
        <p className="b-list-item-type__category">{fields.category}</p>
      )}
      {fields.email && fields.email && (
        <div>
          <a
            href={`mailto:${fields.email}`}
            className="b-list-item-type__email"
            aria-label={fields.email}
          >
            {fields.email}
          </a>
        </div>
      )}
      {fields.phoneNumber && (
        <p>
          <a href={`tel:${fields.phoneNumber}`}>{fields.phoneNumber}</a>
        </p>
      )}
      {fields.position && (
        <div className="b-list-item-type__meta">
          <strong>Stilling: </strong>
          {fields.position}
        </div>
      )}
      {fields.department && (
        <div className="b-list-item-type__meta">
          <strong>Avdeling: </strong>
          {fields.department}
        </div>
      )}
      {fields.topic && (
        <div className="b-list-item-type__topic">{fields.topic}</div>
      )}
    </div>
  );
};

const Report = ({ fields }) => {
  return (
    <div
      key={fields.heading}
      className={classNames({
        'b-list-item-type': true,
        'b-list-item-type--center-arrow': true,
        'b-list-item-type__flex-wrapper': true,
      })}
    >
      <div className="">
        <div className="b-list-item-type__text">
          {fields.heading && <h3>{fields.heading}</h3>}
        </div>

        {fields.sistfagligoppdatert && (
          <div className='b-list-item-type__icon-text'>
            <span className=''>Publisert: {fields.sistfagligoppdatert}</span>
          </div>
        )}
        {fields.lagetAv && (
          <div className='b-list-item-type__icon-text'>
            <span className=''>Laget av: {fields.lagetAv}</span>
          </div>
        )}
      </div>
    </div>
  )
};
