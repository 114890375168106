$('.downloadPdfNormerende').click(function (e) { e.preventDefault(); return false; })

function downloadPdf(serviceUrl, id) {
  if (id && id !== 'null') {
    $.ajax({
      type: 'POST',
      url: serviceUrl + '?id=' + id,
      success: function (data) {
        if (data.url) {
          window.open(
            data.url,
            '_self'
          );
        }
      }
    })
  }
}

/**
 * Ugly hack to ensure that the functions are included in project-bundle.js
 * in /build as tsup filters out unused functions
*/
window.attachment_namespace = {}
window.attachment_namespace.downloadPdf = downloadPdf
