function skipToContent(e) {
  const skipLink = e.target;
  // remove the # from the id
  const targetEl = document.getElementById(skipLink.href.split("#")[1]);
  
  // If targetEl has tabindex already, then we don't want 
  // to add something or remove it
  const hasTabIndex = targetEl.hasAttribute("tabindex");
  
  if(!hasTabIndex) targetEl.setAttribute("tabindex", "-1");
  targetEl.focus();
  if(!hasTabIndex) targetEl.removeAttribute("tabindex");
}

export default skipToContent;
