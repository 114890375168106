"use strict";
(function () {
  const searchBtn = document.getElementById("search-btn");

  if (searchBtn) {
    searchBtn.onclick = function() {
      const inputValue = document.getElementById("search-input").value;
      location.href = `?searchNormerende=${inputValue}`;
    };
  }
})();
