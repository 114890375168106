import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NavList from './NavList';
import InputSearch from './InputSearch';

const MainHeaderHamburgerMenu = ({
  setHamburgerMenuOpen,
  links,
  searchOpen,
  exampleSuggestions = ["eksempeltekst"],
  hamburgerMenuOpen = false
}) => {
  const [scrolledBelowTreshold, setScrolledBelowTreshold] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrolledBelowTreshold(window.scrollY > 120);
    });
  }, []);

  return (
    <div
      className="b-main-header__menu-wrapper"
      role="dialog"
      aria-label="Meny"
    >
      {hamburgerMenuOpen && (
        <div
          className={`b-main-header__menu-underlay ${
            scrolledBelowTreshold
              ? 'b-main-header__menu-underlay--scrolled'
              : ''
          }`}
          onClick={() => setHamburgerMenuOpen(false)}
        ></div>
      )}
      <nav
        className={`b-main-header__menu-overlay ${
          hamburgerMenuOpen ? 'b-main-header__menu-overlay--expanded' : ''
        }`}
        aria-label="Header-navigasjon"
        role="navigation"
        id="mobile-menu"
      >
        <div className="l-mt-1">
          <div
            className={`b-main-header__menu-overlay-links ${
              hamburgerMenuOpen
                ? 'b-main-header__menu-overlay-links--expanded'
                : ''
            }`}
          >
            {hamburgerMenuOpen && (
              <div className="l-hide-from-lg l-mt-2 l-mb-2">
                <InputSearch
                  autoFocus={searchOpen}
                  exampleSuggestions={exampleSuggestions}
                />
              </div>
            )}

            {hamburgerMenuOpen && (
              <NavList
                list={links.map(item => ({
                  title: item.name,
                  url: item.href
                }))}
              />
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

MainHeaderHamburgerMenu.propTypes = {
  setHamburgerMenuOpen: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(PropTypes.object)
};

export default MainHeaderHamburgerMenu;
