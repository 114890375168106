import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const imageClasses = ({ size, alignIconTop }) =>
  classNames({
    'b-icon-with-text__image': true,
    'b-icon-with-text__image--small': size === 'small',
    'b-icon-with-text__image--align-top': alignIconTop
  });
const wrapperClasses = size =>
  classNames({
    'b-icon-with-text': true,
    'b-icon-with-text--clean': size === 'clean'
  });

const IconWithText = ({ size, icon, text, link, subtext, alignIconTop }) => (
  <span className={wrapperClasses(size)}>
    <img
      src={icon}
      alt={""}
      className={imageClasses({ size, alignIconTop })}
      aria-hidden
    />
    <span className="b-icon-with-text__text-wrapper">
      {
        link ? (
          <span>
            <a className="b-icon-with-text__text" href={link}>{text}</a>
            <span className="b-icon-with-text__subtext">
              {subtext}
            </span>
          </span>
        ) : (
          <span className="b-icon-with-text__text">
            {text}
            <span className="b-icon-with-text__subtext">{subtext}</span>
          </span>
        )
      }
    </span>
  </span>
);

IconWithText.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string,
  subtext: PropTypes.string,
  link: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small', 'clean']),
  alignIconTop: PropTypes.bool
};

IconWithText.defaultProps = {
  size: 'medium',
  subtext: '',
  link: ''
};

export default IconWithText;
