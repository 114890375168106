import React from 'react';

const CloseIcon = (props) => {
  return (
    <>
      <svg
        {...props}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 11L11 0.999999" stroke="currentColor" />
        <path d="M11 11L0.999998 0.999999" stroke="currentColor" />
      </svg>
    </>
  );
};

export default CloseIcon;
