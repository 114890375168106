import cn from "classnames";
import React, { useState } from "react";
import Button from "./Button";
import FocusTrap from "focus-trap-react";

// TODO: switch these with icons from static/icons folder, fetch from backend.
import CloseIcon from "./icons/close";
import ExpandIcon from "./icons/expand";

const TableWrapper = (props) => {
  const [isFullScreen, setIsFullScreen] = useState(false)

  const Table = () => <div className={cn("b-table__wrapper")} dangerouslySetInnerHTML={{__html: props.table}}></div>

  const FullScreenView = () => {
    return <div className={cn("b-table__full-screen")}>
      <FocusTrap>
        <div className={"b-table__full-screen-container"}>
          <div>
            <Button className={cn("b-table__full-screen-close-button")} onClick={() => setIsFullScreen(false)} clean>Lukk <CloseIcon /></Button>
          </div>
          <Table />
        </div>
      </FocusTrap>
    </div>
  }

  return <div className={cn("b-table l-mb-2")}>
    <Table />
    {isFullScreen && <FullScreenView  />}
    <div>
      <Button className={cn("b-table__full-screen-open-button")} onClick={() => setIsFullScreen(true)} plain><ExpandIcon /> Vis i fullskjerm</Button>
    </div>
  </div>
}

export default TableWrapper
