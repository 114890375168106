window.loadingStatus = false

function b64toBlob(b64Data, contentType) {
  const sliceSize = 512;
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, {type: contentType});
}

function aria_exportPdf(event, serviceUrl, title, url) {
  if (event.key === ' ' || event.key === 'Enter' || event.key === 'Spacebar') {
    event.preventDefault()
    exportPdf(serviceUrl, title, url)
  }
}

function createHeadingOverview() {
  const layout = $('.b-headings-overview').data('layout')
  const h2Headers = $('h2', '.tekst-content')
  if (layout === 'steg-veileder-kapittel') {
    const iconUrl = $('.icon-info').data('icon-url')
    if (!$('.b-headings-overview__link').length) {
      if (h2Headers.length > 0) {
        $('.b-headings-overview').show()

        $(h2Headers).each(function () {
          const label = $(this).text()
          const customId = label.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '-').toLowerCase()
          const item = `<li><a class="b-headings-overview__link" href="#${customId}"><img src="${iconUrl}"/>${label}</a></li>`

          $(this).attr('id', customId)
          $('.b-headings-overview__list').append(item)
        })
      }
    }
  } else if ($('.b-headings-overview__link').length) {
    if (h2Headers.length > 0) {
      $(h2Headers).each(function () {
        const label = $(this).text();
        const customId = label.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '-').toLowerCase();
        $(this).attr('id', customId)
      })
    }
  }
}

$(document).ready(function () {
  if ($('.b-headings-overview').length) { createHeadingOverview() }

  $('#exportPdf').click(function () {
    const serviceUrl = encodeURI($(this).data('getpdfservice'))
    const url = encodeURI($(this).data('pageurl'))
    const title = $(this).data('title')
    const goalTitles = $('#goalTitle')[0] || undefined

    window.loadingStatus = true

    $.ajax({
      type: 'POST',
      url: serviceUrl,
      dataType: 'json',
      processData: true,
      data: {
        url: url,
        values: goalTitles && goalTitles.textContent && JSON.stringify({
          goalTitle: goalTitles.textContent
        })
      },
      success: function (data) {
        if (data.content && data.content.content) {
          const fileName = title + '.pdf';
          const blob = b64toBlob(data.content.content, 'application/pdf');
          if (window.navigator.msSaveBlob) { // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
            window.navigator.msSaveOrOpenBlob(blob, fileName)
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
            a.download = fileName
            document.body.appendChild(a)
            a.click() // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a)
          }
        }
        window.loadingStatus = false
      },
      error: function (xhr, ajaxOptions, thrownError) {
        console.log(thrownError)
        window.loadingStatus = false
      }
    })
  })

  if ($('#article').length) {
    $('#skip-to-article-content').attr('href', '#article')
  }

  const duplicatedIds = getDuplicatedIds()
  if (duplicatedIds.length) {
    for (const id of duplicatedIds) {
      $('*').filter('#' + id).each(function () {
        $(this).attr('id', id + '-' + randomId() + randomId() + '-' + randomId())
      })
    }
  }

  // Removes all empty bullet lists
  $('ul').each(function () {
    if ($(this).has('li').length === 0) { $(this).remove() }
  })
})

function randomId() {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
}

function exportPdf(serviceUrl, title, url) {
  window.loadingStatus = true
  console.log(serviceUrl)
  $.ajax({
    type: 'POST',
    url: serviceUrl,
    dataType: 'json',
    processData: true,
    data: {
      url: url,
      values: JSON.stringify({
        goalTitle: Object.keys($('#goalTitle')).length != 0 ? $('#goalTitle')[0].textContent : ''
      })
    },
    success: function (data) {
      if (data.content && data.content.content) {
        const fileName = title + '.pdf';
        const blob = b64toBlob(data.content.content, 'application/pdf');
        if (window.navigator.msSaveBlob) { // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
          window.navigator.msSaveOrOpenBlob(blob, fileName)
        } else {
          const a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
          a.download = fileName
          document.body.appendChild(a)
          a.click() // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
          document.body.removeChild(a)
        }
      }
      window.loadingStatus = false
    },
    error: function (xhr, ajaxOptions, thrownError) {
      window.loadingStatus = false
    }
  })
}

function downloadVersion(documasterUrl, id) {
  $.ajax({
    type: 'POST',
    url: documasterUrl,
    dataType: 'json',
    processData: true,
    data: {
      id: id
    },
    success: function (data) {
      const file = data.file
      const mimeType = data.mimeType
      const fileName = data.fileName
      const blob = b64toBlob(file, mimeType)
      if (window.navigator.msSaveBlob) { // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob, { type: mimeType })
        a.download = fileName
        document.body.appendChild(a)
        a.click() // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a)
      }
    },
    error: function (xhr, ajaxOptions, thrownError) {
      console.log('error')
    }
  })
}

// Accessibility
$(document).ready(function () {
  if ($('.b-table__wrapper').length) {
    $('.b-table__wrapper').each(function () {
      const tabindex = $(this).attr('tabindex')
      if (!(tabindex !== undefined && tabindex !== false)) {
        $(this).attr('tabindex', '0')
      }
    })
  }
})

// get all ids from current page
function fetchPageIds() {
  return $('*').map(function () {
    if (this.id) {
      return this.id
    }
  }).get()
}

function getDuplicatedIds() {
  const ids = fetchPageIds()
  const duplicatedIds = []

  if (ids.length) {
    const sortedIds = ids.sort()
    for (let i = 0; i < sortedIds.length - 1; i++) {
      if (sortedIds[i + 1] == sortedIds[i] && duplicatedIds.indexOf(sortedIds[i]) == -1) {
        duplicatedIds.push(sortedIds[i])
      }
    }
  } return duplicatedIds
}

/**
 * Ugly hack to ensure that the functions are included in project-bundle.js
 * in /build as tsup filters out unused functions
*/
window.file_namespace = {};
window.file_namespace.downloadVersion = downloadVersion;
