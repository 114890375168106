import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PropTypes from 'prop-types';
import InputSearch from './InputSearch';
import { debounce } from 'lodash';
import List from './List';
import Button from './Button';
import { ContentSearchFilter } from './ContentSearchFilter';
import ArrowIcon from './icons/arrow';

/*
 ** This component is used for:
 ** - search field + results
 ** - only results (when used in a wizard)
 **
 ** Uses API search (either by search word or wizard options), fetches results, displays them divided into tab groups.
 **
 */

export const transformInitial = data => {
  let parsedData = '';
  if (typeof data === 'string' || data instanceof String) {
    parsedData = JSON.parse(data);
    parsedData = parsedData.reduce(
      (obj, item) => Object.assign(obj, item),
      {}
    );

    return parsedData;
  }
  return data;
};

const ContentSearchWithTabs = ({
  id,
  label,
  endpoint,
  showEntireList,
  truncateListAfter = 7,
  initial = null,
  malgruppe,
  categories,
  collapsed,
  pageLength,
  orderBy = 'frist',
  showLabel = false,
  availableFilters = ''
}) => {
  const activeLabels = ['Pågående', 'Kommende'];
  const inactiveLabels = ['Utløpt', 'Gjennomførte'];
  const defaultLabel = 'All';

  // parse available filters to make doing checks easier
  availableFilters = (() => {
    try {
      return JSON.parse(availableFilters)
    } catch {
      return availableFilters
    }
  })()

  const [searchResults, setSearchResults] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [toggleMore, setToggleMore] = useState([]);
  const [formDropValue, setFormDropValue] = useState(malgruppe || []);
  const [formRadioValue, setFormRadioValue] = useState([]);
  const [formCheckValue, setFormCheckValue] = useState(categories || []);
  const [filteredList, setFilteredList] = useState(availableFilters?.length ? transformInitial(initial) : null);
  const [listChanged, setListChanged] = useState(false);
  const liveSearchUrl = endpoint;

  useEffect(() => {
    // set this to trigger a new filter action
    setListChanged(true);

    if(searchString && searchString.length > 2 && searchResults) {
      setFilteredList(searchResults)
      return
    }

    setFilteredList(initial)
  }, [searchResults, searchString])

  const fetchResultsBySearch = value => {
    fetch(
      liveSearchUrl +
        '?length=' +
        pageLength +
        '&searchQuery=' +
        value +
        '&id=' +
        id
    )
      .then(res => res.json())
      .then(data => {
        // Transform array of objects, to object
        const results = data.reduce(
          (obj, item) => Object.assign(obj, item),
          {}
        );
        setSearchResults(results);
        setToggleMore(false);
        setLoading(false);
      });
  };
  const fetchResultsWizard = () => {
    const dropValueQuery = formDropValue ? '&dropValue=' + formDropValue : '';
    const checkValueQuery = formCheckValue
      ? '&checkValue=' + formCheckValue
      : '';
    const radioValueQuery = formRadioValue
      ? '&radioValue=' + formRadioValue
      : '';
    fetch(
      liveSearchUrl +
        '?length=' +
        pageLength +
        dropValueQuery +
        checkValueQuery +
        radioValueQuery +
        '&id=' +
        id
    )
      .then(res => res.json())
      .then(data => {
        // Transform array of objects, to object
        const results = data.reduce(
          (obj, item) => Object.assign(obj, item),
          {}
        );
        setSearchResults(results);
        setToggleMore(false);
        setLoading(false);
      });
  };

  const debouncedChange = useCallback(
    value => {
      if (value.length > 2) {
        setSearchString(value);
      }
      if (value.length === 0) {
        setSearchResults(null);
        setSearchString('');
      }
    },
    [searchResults]
  );

  const doSearch = useMemo(() => debounce(fetchResultsBySearch, 1000, true), [
    debouncedChange
  ]);

  const debouncedTrigger = useCallback(
    () => {
      setLoading(true);
      doSearch(searchString);
    }
  )

  const getValuesFromSelected = (group, query) => {
    /*
     * HELPER
     * Sort out which values are selected and not.
     * We do this because there may be multiple field groups on the page,
     * so we need to sort out which ones to keep and not.
     */
    const allNodes = group.querySelectorAll(query);
    const selectedNodes = group.querySelectorAll(query + ':checked');
    let selected = [];
    let notSelected = [];
    selectedNodes.forEach(node => {
      selected.push(node.value.replace(',', ''));
    });
    allNodes.forEach(node => {
      if (!selected.includes(node.value)) {
        notSelected.push(node.value.replace(',', ''));
      }
    });
    return {
      selected,
      notSelected
    };
  };

  useEffect(() => {
    /*
     ** WIZARD MODE
     ** When used on wizard page, collect values from the form inputs, update state,
     ** then trigger new search.
     */
    const steps = [...document.querySelectorAll('section[data-step]')];
    steps.forEach(step => {
      const inputType = step.dataset.inputType;
      const nextBtn = step.querySelector('button[data-next]');

      if (inputType === 'dropValue') {
        const input = step.querySelector('select');

        input.addEventListener('change', function(e) {
          nextBtn && nextBtn.addEventListener('click', function() {
              const group = e.target.parentNode.parentNode;
              const { notSelected } = getValuesFromSelected(
                group,
                'option'
              );
              setFormDropValue(existingValues => {
                return [
                  ...existingValues
                    .filter(value => !notSelected.includes(value))
                    .filter(el => el != null),
                  e.target.value.replace(',', '')
                ].filter(function(item, pos, self) {
                  // Make sure array has unique items
                  return self.indexOf(item) == pos;
                });
              });
            });
        });
      }

      if (inputType === 'checkValue') {
        const inputs = step.querySelectorAll('input[type="checkbox"]');

        nextBtn && nextBtn.addEventListener('click', function(e) {
            let values = [];
            let availableValues = [];
            inputs.forEach(input => {
              if (input.checked) {
                values.push(input.value);
              }
              if (!input.checked && values.find(cat => cat === input.value)) {
                values.filter(value => !input.value);
              }
              availableValues.push(input.value);
            });
            setFormCheckValue(existingValues => {
              if (existingValues.length > 0 && Array.isArray(existingValues)) {
                return [
                  ...existingValues
                    .filter(value => !values.includes(value))
                    .filter(value => !availableValues.includes(value))
                    .filter(el => el != null),
                  ...values
                ].filter(function(item, pos, self) {
                  // Make sure array has unique items
                  return self.indexOf(item) == pos;
                });
              } else {
                return [...values];
              }
            });
          });
      }

      if (inputType === 'radioValue') {
        const radios = step.querySelectorAll('input[type="radio"]');

        radios.forEach(input => {
          input.addEventListener('change', function(e) {
            nextBtn &&
              nextBtn.addEventListener('click', function() {
                const group = e.target.parentNode.parentNode;
                const { notSelected } = getValuesFromSelected(
                  group,
                  'input[type="radio"]'
                );
                setFormRadioValue(existingValues => {
                  return [
                    ...existingValues
                      .filter(el => !notSelected.includes(el))
                      .filter(el => el != null),
                    e.target.value
                  ].filter(function(item, pos, self) {
                    // Make sure array has unique items
                    return self.indexOf(item) == pos;
                  });
                });
              });
          });
        });
      }
    });
  }, []);

  useEffect(() => {
    // Wizard mode, trigger search on input changes
    if (!initial) {
      setLoading(true);
      fetchResultsWizard();
    }
  }, [formDropValue, formCheckValue, formRadioValue]);

  const changeToggleMore = (name, value) => {
    setToggleMore(prevObj => {
      return {
        ...prevObj,
        [name]: value
      };
    });
  };

  useEffect(() => {
    const data = searchResults ? searchResults : initial;
    const keys = data ? Object.keys(data) : [];

    setToggleMore(
      keys.map(key => {
        return {
          [key]: false
        };
      })
    );
  }, []);

  // TODO: this is never used, showEntireList is never passed to frontend, and therefore always false. Decide if delete or include as an option.
  useEffect(() => {
    if(!showEntireList) return

    const returnToggleAllTrue = (obj) => {
      const keys = Object.keys(obj)
      return keys.reduce((acc, curr) => {
        acc[curr] = true
        return acc
      }, {})
    }

    if(filteredList) {
      setToggleMore(returnToggleAllTrue(filteredList))
    } else {
      setToggleMore(returnToggleAllTrue(transformInitial(initial)))
    }
  }, [])

  const getTotal = results => {
    // Exclude the "Alle" group from the total count
    const numbers = Object.entries(results)
      .filter(cat => cat[0] !== 'Alle')
      .map(cat => cat[1].length);
    return numbers.reduce((a, b) => a + b, 0);
  };

  const orderByAttribute = (arr, reverse) => {
    return arr.sort(function(a, b) {
      // Check if frist object is present. When it doesn't exists, this means it is "løpende" and should be placed at bottom.
      if (!a.fields.hasOwnProperty(orderBy)) {
        return 1;
      }
      if (!b.fields.hasOwnProperty(orderBy)) {
        return -1;
      }

      // Order the rest by date
      const { date: dateB } = b.fields[orderBy];
      const { date = null } = a.fields[orderBy];
      if (!date) {
        return;
      }
      const aDate = new Date(date);
      const bDate = new Date(dateB);
      return reverse
        ? Number(bDate) - Number(aDate)
        : Number(aDate) - Number(bDate);
    });
  };

  const isEmpty = obj => {
    if (!obj) {
      return true;
    }
    return Object.keys(obj).length === 0;
  };

  const tabPanels = data => {
    let parsedData = transformInitial(data);
    const keys = parsedData ? Object.keys(parsedData) : [];

    return keys.map(key => {
      /*
       ** Tab content
       */

      let allData = parsedData
        ? parsedData[key].map(item => {
            return {
              ...item,
              type: item.type || 'generic',
              fields: {
                ...item.fields,
                // Skip if there are no keys (key equals default label)
                expired:
                  key === defaultLabel ? false : inactiveLabels.includes(key),
                // Skip if there are no keys (key equals default label)
                generic:
                  key === defaultLabel
                    ? false
                    : !inactiveLabels.includes(key) &&
                      !activeLabels.includes(key)
              }
            };
          })
        : [];
        // hide items with "hidden" property
        allData = allData.filter(item => !item.hidden);

      const reverseOrder = inactiveLabels.includes(key);
      allData = orderByAttribute(allData, reverseOrder);


      const splicedData = allData.length > truncateListAfter ? allData.slice(0, truncateListAfter) : null;
      return (
        <TabPanel key={key}>
          <List
            list={toggleMore[key] || allData.length < truncateListAfter + 1 ? allData : splicedData}
          />
          {!toggleMore[key] && allData.length > truncateListAfter ? (
            <div className="row center-xs">
              <div className="l-mt-3">
                <Button onClick={() => changeToggleMore(key, true)} secondary>
                  <ArrowIcon rotate={180} /> Vis flere
                </Button>
              </div>
            </div>
          ) : null}
        </TabPanel>
      );
    });
  };

  const getTabNames = data => {
    const noTabs = data.filter(tab => tab === 'All');
    const activeList = transformInitial(filteredList || initial) || searchResults

    const generateTabNumber = (key) => {
      // active list might be undefined for a tick, so important to optional chain filter
      return activeList[key]?.filter(item => !item.hidden).length
    }

    return noTabs.length > 0 ? (
      <TabList className="hide">
        <Tab></Tab>
      </TabList>
    ) : (
      <TabList>
        {data.map(key => (
          <Tab key={key}>
            {key.charAt(0).toUpperCase() + key.slice(1)}
            {activeLabels.includes(key) ? (
              <span className="react-tabs__tab-count react-tabs__tab-count--green">
                {generateTabNumber(key)}
              </span>
            ) : null}
            {inactiveLabels.includes(key) ? (
              <span className="react-tabs__tab-count react-tabs__tab-count--red">
                {generateTabNumber(key)}
              </span>
            ) : null}
          </Tab>
        ))}
      </TabList>
    );
  };

  return (
    <>
      <div id={id || 'content-search'} className="b-product-search">
        {!collapsed ? (
          <InputSearch
            id={`content-search-input-${id}`}
            label={label}
            biggerLabel
            autoFocus={false}
            showSuggestions={false}
            fnChange={debouncedChange}
            fnClear={debouncedChange}
            customTrigger={debouncedTrigger}
          />
        ) : null}
        {showLabel && collapsed && <h2>{label}</h2>}
      </div>
      {Array.isArray(availableFilters) && availableFilters?.length > 0 && (
        <div>
          <ContentSearchFilter
            initial={initial}
            setFilteredList={setFilteredList}
            filteredList={filteredList}
            availableFilters={availableFilters}
            listChanged={listChanged}
            setListChanged={setListChanged}
            // filterAlgorithm={'simpleOrFilter'}
          />
          {/* TODO: uncomment filterAlgorithm */}
        </div>
      )}
      {
        // TODO: Replace this svg with the Loading component
      }
      {loading ? (
        <div>
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
          >
            <path
              fill="#0667c6"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.8s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      ) : null}

      {// INITIAL RESULTS / DEFAULT
      initial && !searchResults && !loading ? (
        <Tabs>
          {getTabNames(
            Object.keys(transformInitial(initial))
          )}
          {tabPanels(transformInitial(filteredList ?? initial))}
        </Tabs>
      ) : null}

      {// SEARCH RESULTS
      searchResults ? (
        <div className="l-mb-4 results">
          {searchString.length > 2 && searchResults && !loading && !availableFilters ? (
            <h2 className="b-product-search__title">
              {getTotal(searchResults)} treff på «{searchString}»
            </h2>
          ) : null}
          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            {getTabNames(Object.keys(transformInitial(filteredList) || searchResults))}
            {tabPanels(filteredList || searchResults)}
          </Tabs>
        </div>
      ) : null}

      {// NO SEARCH RESULTS: WIZARD MODE
      !initial && !loading && isEmpty(searchResults) ? (
        <div className="l-mb-4">
          <div className="col-xs-12 l-mt-2 l-mb-3">
            <p>Fant ingen resultater. Prøv å endre noen av valgene dine.</p>
          </div>
        </div>
      ) : null}
    </>
  );
};

ContentSearchWithTabs.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  endpoint: PropTypes.string,
  initial: PropTypes.string,
  malgruppe: PropTypes.string,
  categories: PropTypes.array,
  collapsed: PropTypes.bool,
  pageLength: PropTypes.string,
  showLabel: PropTypes.bool,
  showEntireList: PropTypes.bool
};

export default ContentSearchWithTabs;
