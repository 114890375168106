import React from 'react';

const ArrowUpIcon = ({rotate = 0}) => {
  return (
    <svg
      width="0.6rem"
      height="0.6rem"
      viewBox="0 0 44 43"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotate})`}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="arrow_up_hdir"
          transform="translate(0.752000, 0.376000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <path
            d="M21.44,-1.42108547e-14 C24.1280134,3.75468544 27.0826506,7.09331872 30.304,10.016 C33.5253494,12.9386813 37.5893088,15.8933184 42.496,18.88 L39.168,22.912 C31.8719635,18.0906426 26.5813498,13.3973562 23.296,8.832 C23.637335,12.6720192 23.808,16.362649 23.808,19.904 L23.808,42.624 L18.688,42.624 L18.688,19.904 C18.688,16.362649 18.858665,12.6720192 19.2,8.832 C15.9146502,13.3973562 10.6240365,18.0906426 3.328,22.912 L-2.84217094e-14,18.88 C4.9066912,15.8933184 8.97065056,12.9386813 12.192,10.016 C15.4133494,7.09331872 18.3679866,3.75468544 21.056,-1.42108547e-14 L21.44,-1.42108547e-14 Z"
            id="↑"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default ArrowUpIcon
