/**
 *
 * @param {HTMLElement} parent
 *
 * This will make a list closable. Meant to be used for
 * navigation list that you can hide to make navigation easier.
 *
 * this will look for a button, a ul and child li elements.
 *
 * the parent element needs:
 * id="headings-overview-list" — lets us target the list on page load.
 *
 * the toggle button needs two elements that set the open and close markup for the button:
 * - data-button-open
 * - data-button-close
 */
export const makeListClosable = (root) => {
  const ul = root.querySelector('ul');
  const button = root.querySelector('button');

  // this is the display html for the open and closed state
  const buttonOpenInner = button.querySelector("[data-button-open]");
  const buttonCloseInner = button.querySelector("[data-button-close]");


  // exit if button/ul is not found
  if(!button) {
    throw new Error('Could not find button element.');
  }
  if(!ul) {
    throw new Error('Could not find ul element.');
  }

  let isOpen = true;
  const ulId = 'closable-element-list';

  button.setAttribute('aria-expanded', 'true');
  button.setAttribute('aria-controls', ulId);

  ul.setAttribute('id', ulId);
  ul.setAttribute('role', 'region');

  buttonOpenInner.setAttribute("hidden", true)

  button.addEventListener('click', e => {
    const el = e.target;
    e.preventDefault();

    // on close
    if(isOpen) {
      el.setAttribute('aria-expanded', 'false');
      ul.setAttribute('hidden', true);

      buttonCloseInner.setAttribute("hidden", true);
      buttonOpenInner.removeAttribute("hidden");

      // on open
    } else {
      el.setAttribute('aria-expanded', 'true');
      ul.removeAttribute('hidden');

      buttonOpenInner.setAttribute("hidden", true);
      buttonCloseInner.removeAttribute("hidden");
    };

    isOpen = !isOpen;
  });
};

const setInner = (inner, btn) => {
  btn.innerHtml = '';
  btn.innerText = '';

  if(typeof inner === 'string') {
    btn.innerText = inner;

  } else {
    btn.append(inner);

  }
};
